<template>
  <WeContainer v-if="ready" header="true" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:data="cargo.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      list: [],
      actions: [
        {
          text: "Yeni Kargo Firması Ekle",
          path: "shipping-companies/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "shipping-companies/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "name", th: "Firma Adı ", type: "string" },
        { name: "cargo_company", th: "Kargo Şirketi", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
    };
  },
  methods: {
    ...mapActions("cargo", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("shipping-companies/detail/" + row.id);
    },
    onDelete(row) {
      let message = `${row.name} adlı kargo firmasını silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Kargo Firması Başarıyla Silindi");
                helper.arrayRemove(this.cargo.list, "id", row.id);
              } else {
                this.$swal({
                  title: "Kargo Firması Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    checkPermission(code) {
      return permission.check("cargo", code);
    },
  },
  computed: {
    ...mapState(["cargo"]),
  },
  mounted() {
    this.getList({
      onFinish: () => {
        this.ready = true;
      },
    });
  },
};
</script>
